import dynamic from 'next/dynamic'
import { useEffect, useRef, useState } from 'react'

const Toastr = dynamic(() => import('@shared/components/toastr/Toastr'))

function ToastrWrapper() {
  const [isVisible, setIsVisible] = useState(false)
  const data = useRef({})

  useEffect(() => {
    function handleChange({ detail }) {
      data.current = detail
      setIsVisible(true)
    }
    window.addEventListener('toasterEvent', handleChange)
    return () => window.removeEventListener('toasterEvent', handleChange)
  }, [])
  if (isVisible) {
    return <Toastr {...data.current} onCloseEvent={setIsVisible} />
  } else return null
}
export default ToastrWrapper
